import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import selling from 'images/selling.svg';
import React from 'react';
import styled from 'styled-components';
import {IndexImage} from 'components/landings/IndexImage';

type Props = {
  pageContext: {
    pagePath: string;
    title: string;
  };
  path: string;
};

const IndexSection = styled.div`
  position: relative;
`;

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 90px;
  max-width: 500px;
`;

const SellingPage: React.FC<Props> = ({pageContext, path}) => {
  const {t} = useI18next();
  const titleId = `selling.${pageContext.pagePath}`;
  const descriptionId = `selling.${pageContext.pagePath}.description`;
  const metaDescriptionId = `selling.${pageContext.pagePath}.meta_description`;
  // i18next-extract-disable-next-line
  const title = t(titleId);
  const description = t(
    'selling.description',
    `With MONEI, we set out to redefine our understanding of e-commerce. With child-like simplicity, military grade security and speed of a superhero, we made it the best experience possible for our merchants.`
  );
  const metaDescription = t(
    'Grow your e-commerce business with MONEI’s payment gateway: Dynamic pricing. More payment methods. E-commerce platform or custom website integration. Join »'
  );
  return (
    <Content>
      <SEO
        path={path}
        title={title}
        /* i18next-extract-disable-next-line */
        description={t(metaDescriptionId, metaDescription)}
        bodyAttributes={{class: 'selling-page'}}
      />
      <IndexSection>
        <IndexContent>
          <SectionHeader underline tagName="h1">
            {title}
          </SectionHeader>
          <div
            dangerouslySetInnerHTML={{
              /* i18next-extract-disable-next-line */
              __html: t(descriptionId, description, {interpolation: {escapeValue: false}})
            }}
          />
          <SectionActions align="left">
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
            <ContactSalesButton />
          </SectionActions>
        </IndexContent>
        <IndexImage
          left="50%"
          top="120px"
          width="700px"
          src={selling}
          title={title}
          alt={title}
          className="hide-on-mobile"
        />
      </IndexSection>
    </Content>
  );
};

export default SellingPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "selling-page"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
